<template>
  <b-modal
    v-model="state"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    @click.prevent.stop
  >
    <div class="h1 d-inline-flex align-items-center text-danger w-100">
      <font-awesome-icon :icon="['fas', 'trash']" class="mr-3" />
      <div class="h3 font-weight-bold">
        {{ $t('fCustomer_permDel_title') }}
      </div>
    </div>
    <div class="body">
      <p v-if="isDeleted" >{{ $t('fCustomer_permDel_result') }}</p>
      <p v-else >{{ $t('fCustomer_permDel_desc') }}</p>
      <table class="table b-table table-striped table-hover table-sm">
        <tbody role="rowgroup">
          <tr v-for="col of listColumns" :key="col" role="row">
            <th class="w-75 font-weight-bold" role="cell">
              {{ col }}
            </th>
            <td class="w-25 text-right" role="cell">
              <font-awesome-icon v-if="isDeleted" :icon="['fas', 'check']" />
              <span v-else>{{ permanentDeleteList[col] }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-inline-flex align-items-center justify-content-end w-100">
      <b-button
        v-if="!isDeleted"
        :disabled="busy"
        class="mt-3 mx-3"
        variant="danger"
        size="md"
        block
        @click.stop="onConfirm"
      >
        {{ $t('fCustomer_permDel_ok') }}
      </b-button>
      <b-button
        v-if="!isDeleted"
        :disabled="busy"
        class="mt-3 mx-3"
        variant="secondary"
        size="md"
        block
        @click.stop="onCancel"
      >
        {{ $t('fCustomer_permDel_cancel') }}
      </b-button>
      <b-button
        v-if="isDeleted"
        :disabled="busy"
        class="mt-3 mx-3"
        variant="success"
        size="md"
        block
        @click.stop="onFinish"
      >
        {{ $t('fCustomer_permDel_finish') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'PermanentDeleteDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      itemId: this.userId,
      state: this.value,
      busy: false,
      isDeleted: false
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'permanentDeleteList'
    ]),
    listColumns () {
      const cols = Object.keys(this.permanentDeleteList).filter((col) => {
        return this.permanentDeleteList[col] > 0
      })
      return cols
    }
  },
  watch: {
    value (n) {
      this.state = n
      if (n) {
        this.busy = true
        this.getPermanentDeleteList(this.userId)
        this.busy = false
      }
    },
    state (n) {
      this.$emit('input', n)
    }
  },
  methods: {
    ...mapActions({
      getPermanentDeleteList: 'forms/customerEdit/permanentDeleteList',
      permanentDelete: 'forms/customerEdit/permanentDelete'
    }),
    async onConfirm () {
      try {
        this.busy = true
        await this.permanentDelete(this.userId)
        this.isDeleted = true
      } catch (e) {} finally {
        this.busy = false
      }
    },
    onCancel () {
      this.state = false
    },
    onFinish () {
      this.$emit('finished')
      this.$nextTick(() => {
        this.state = false
      })
    }
  }
}
</script>

<style scoped>

</style>
